import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Android from '../images/job/android.png'
import Apple from '../images/job/apple.png'
import BlogForm from './Company/Blog/BlogForm'


const Job = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "jobBanner.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/job/">
                        <section>
                            <div className="banner_section">
                                <Img
                                    Tag="section"
                                    fluid={imageData}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div>
                                <div className="job_banner">
                                    <Container>
                                        <Row>
                                            <Col md="6">
                                                <h1> Job Search <br />Anytime Anywhere</h1>
                                                <div className="job_card">
                                                    <div className="job_box">
                                                        <p>For Android</p>
                                                        <a href="https://play.google.com/store/apps/details?id=com.tridentjobs&hl=en_US">
                                                            <img src={Android} alt="img" />
                                                        </a>
                                                    </div>
                                                    <div className="job_box">
                                                        <p>For iPhone, iPod and iPad</p>
                                                        <a href="https://apps.apple.com/us/app/trident-jobs/id1309658454">
                                                            <img src={Apple} alt="img" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        <Col md="12">
                                            <div className="text_center">
                                                <h3>Job Search</h3>
                                            </div>
                                            <div>
                                                <iframe src="https://www2.jobdiva.com/candidates/myjobs/searchjobsdone.jsp?compid=-1&amp;a=z4jdnwcae7srj2vf067fzzx2sdncjs0395o3bvedx5in660k1iyuj32wbhrd7ytc" width="100%" height="650px" frameborder="0" marginwidth="0" marginheight="0" scrolling="yes"></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find out what Trident can do for you.</h1>
                                        <div className="footer_button">
                                            <a onClick={toggle} href>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Find out what Trident can do for you."  close={()=> toggle()}/>
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                        {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="Jobs" pathname={props.location.pathname} /> */}


                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Job)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection